import React, { useState, useEffect, useContext, useCallback } from "react"
import clsx from "clsx"
import { window } from "browser-monads"
import { snake } from "@src/snake/core"
import { makeStyles } from "@material-ui/core/styles"
import { Backdrop } from "@material-ui/core"

import OfferDrawer from "../components/OfferDrawer"
import Progress from "../components/Progress"

import { detectAlpha2Country } from "../utils/country"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"

// import Bugsnag from "@bugsnag/js"

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))

const goToCheckout = (
  currency,
  planCode,
  issue,
  productCode,
  productVariant,
  checkoutUrl,
  state,
  repo
) => {
  const country = detectAlpha2Country()

  // GTM
  if (window.initialDataLayer) {
    const { cdevent, product } = window.initialDataLayer

    if (cdevent) {
      window.dataLayer.push({
        ...cdevent,
        offerType: `${planCode}`,
        startingIssue: `${issue}`,
      })
    }
    if (product) {
      window.dataLayer.push({
        event: "addToCart",
        ecommerce: {
          currencyCode: `${currency}`,
          add: {
            products: [
              {
                ...product,
                variant: `${productVariant}`,
                quantity: 1,
              },
            ],
          },
        },
      })
    }
  }

  // -- log variables
  var configVars = {
    country: country,
    currency: currency,
    planCode: planCode,
    issue: issue,
    productCode: productCode,
    productVariant: productVariant,
    checkoutUrl: checkoutUrl,
    state: state,
  }

  // -- Fix to manage different endpoints for new checkout (countrie based release)
  /*
  const countriesReleasedRaw = `${process.env.GATSBY_CHECKOUT_COUNTRIES_RELEASED}`
  */

  // -- Default one
  let checkoutUrlForced = `${process.env.GATSBY_CHECKOUT_BASE_URL}/${country}/subscribe`

  /*
  if (countriesReleasedRaw != "") {
    const countriesReleasedArr = countriesReleasedRaw.split("|")
    if (countriesReleasedArr.includes(country)) {
      checkoutUrlForced = `${process.env.GATSBY_CHECKOUT_BASE_URL_REWRITE}/${country}/subscribe`
      console.debug(
        "[New checkout]: Country " +
          country +
          " is on new checkout -> " +
          checkoutUrlForced
      )
    }
  }
  */

  if (!state.cta.force) {
    let domainRedirect = !checkoutUrl ? checkoutUrlForced : checkoutUrl

    let queryString = `sku=${productVariant}`
    if (checkoutUrl) {
      queryString = `product-code=${productCode}`
    }

    setTimeout(() => {
      console.log(
        "Debug: GoToCheckoutLocationTimeout: noforce",
        `${domainRedirect}?${queryString}`
      )
      console.log("Debug: Config variables", configVars)
      window.gbugs.notify({
        message: "GoToCheckoutLocationTimeout",
        errorDetails: `${domainRedirect}?${queryString}`,
        responseText: "If inside goToCheckout()",
      })
      console.warn("Debug: 5 seconds trigger expired...")
    }, 5000)

    window.location.href = `${domainRedirect}?${queryString}${
      !!parseInt(repo?.checkoutTestCheck || 0)
        ? "&testId=" + (repo?.checkoutTestId || "missing") + "&testVersion=b"
        : ""
    }`
  } else {
    setTimeout(() => {
      console.log("Debug: GoToCheckoutLocationTimeout: force", state.cta.force)
      console.log("Debug: Config variables", configVars)
      window.gbugs.notify({
        message: "GoToCheckoutLocationTimeout",
        errorDetails:
          typeof state.cta.force === "string"
            ? state.cta.force
            : JSON.stringify(state.cta.force),
        responseText: "Else inside goToCheckout()",
      })
      console.warn("Debug: 5 seconds trigger expired...")
    }, 5000)

    window.location.href =
      state.cta.force +
      (!!parseInt(repo?.checkoutTestCheck || 0)
        ? "&testId=" + (repo?.checkoutTestId || "missing") + "&testVersion=b"
        : "")
  }
}

const CtaModule = props => {
  const classes = useStyles()
  const { ...others } = props

  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  const [isLoading, setIsLoading] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  //const [plans, setPlans] = useState([])
  //const [offer, setOffer] = useState()
  const [selectedIssue, setSelectedIssue] = useState("")
  const [checkoutCode, setCheckoutCode] = useState()
  //const [deliveryPlan, setSelectedDeliveryPlan] = useState("")
  //const [selectedOfferType, setSelectedOfferType] = useState("")
  const [notifyme, setNotifyMe] = useState(false)
  const [offerData, setOfferData] = useState([])

  const [dtcOnly, setDtcOnly] = useState(false)
  const [showIssueSelector, setShowIssueSelector] = useState(true)

  const [deliveryPlans, setDeliveryPlans] = useState([])
  const [offerTypes, setOfferTypes] = useState([])
  const [issues, setIssues] = useState(null)
  const [issue, setIssue] = useState(null)
  const [deliveryPlan, setDeliveryPlan] = useState("RE")
  const [offerType, setOfferType] = useState("ST")
  const [openPlanConf, setOpenPlanConf] = useState(false)

  const [currencyConf, setCurrencyConf] = useState("")

  // filter cms plans that are non present in the offer api response
  const planFilter = (offer, p) =>
    offer && offer?.offerTypes && offer?.offerTypes[p.planCode]

  useEffect(() => {
    // get data from offer api
    if (state.cta.productCode) {
      setIsLoading(true)

      let productVersion = "00"

      // HOTFIX product version for GOF220000000,GOF414000000,DNF446000000
      if (
        window.location
          .toString()
          .includes("/movie-series/fast-and-furious-cars-b") ||
        window.location
          .toString()
          .includes("/movie-series/optimus-prime-transformers-b") ||
        window.location
          .toString()
          .includes("/fast-and-furious/nissan-skyline-b")
      )
        productVersion = "01"

      snake
        .getOfferByCode(state.cta.productCode, productVersion)
        .then(resultData => {
          setOfferData(resultData)
          setIsLoading(false)

          console.debug("Entire payload...")
          console.log(resultData)

          if (!resultData?.notify) {
            // -- extract starting point (delivery plans)
            let response = resultData.deliveryPlans

            // -- set if product is D2C Only or not
            setDtcOnly(resultData.dtc)

            // -- set if issue selector needs to be visible
            setShowIssueSelector(resultData.showIssueSelector)

            // -- if existing RE -> remove PL
            console.log("response", response)
            if (response["RE"] && response["PL"]) {
              delete response["PL"]
            }

            // -- tech replace 12 with M12 -> to manage index as a string
            Object.keys(response).forEach(item => {
              if (item == "12") {
                response["M12"] = response[item]
                delete response["12"]
              }
            })

            // -- set list of delivery plans
            setDeliveryPlans(response)

            const cmsDefaultDeliveryPlan =
              (resultData?.defaultDeliveryPlans || "RE") == "12"
                ? "M12"
                : resultData?.defaultDeliveryPlans || "RE"

            const defaultDeliveryPlan = Object.keys(response).includes(
              cmsDefaultDeliveryPlan
            )
              ? cmsDefaultDeliveryPlan
              : Object.keys(response).slice(0, 1)[0]

            setDeliveryPlan(defaultDeliveryPlan)

            const firstDelivery = defaultDeliveryPlan

            const nDeliveryPlans = Object.keys(resultData?.deliveryPlans).length
            let nOfferType = Object.keys(
              resultData?.deliveryPlans[firstDelivery].offerTypes
            ).length
            if (
              Object.keys(
                resultData?.deliveryPlans[firstDelivery].offerTypes
              ).indexOf("IN") !== -1
            ) {
              nOfferType = nOfferType - 1
            }
            const iss = Object.values(
              resultData.deliveryPlans[firstDelivery].offerTypes
            )[0].issues
            const nIssues = Object.values(iss).length
            setCurrencyConf(resultData.currency)
            if (nDeliveryPlans === 1 && nOfferType === 1 && nIssues === 1) {
              setOpenPlanConf(false)
              setOpenDrawer(false)
              const currency = resultData.currency
              const productCode = resultData.code
              const productVariant = iss[1]?.sku || iss[Object.keys(iss)].sku
              goToCheckout(
                currency,
                Object.keys(
                  resultData?.deliveryPlans[firstDelivery].offerTypes
                ),
                iss[1],
                productCode,
                productVariant,
                resultData.checkoutUrl,
                state,
                others?.repo
              )
            } else {
              if (resultData.deliveryPlans[firstDelivery]) {
                setOpenPlanConf(true)
                setOpenDrawer(true)
                setDeliveryPlan(firstDelivery)

                // resultData
                if (
                  resultData.deliveryPlans[firstDelivery].offerTypes["ST"] ||
                  resultData.deliveryPlans[firstDelivery].offerTypes["PR"]
                ) {
                  let offerConst = "ST"
                  // Choose first premium

                  // resultData
                  if (
                    resultData.deliveryPlans[firstDelivery].offerTypes["PR"]
                  ) {
                    offerConst = "PR"
                  }

                  // sort offers by type
                  setOfferTypes(
                    // resultData
                    Object.keys(
                      resultData.deliveryPlans[firstDelivery].offerTypes
                    )
                      .sort()
                      .reduce((obj, key) => {
                        // resultData
                        obj[key] =
                          resultData.deliveryPlans[firstDelivery].offerTypes[
                            key
                          ]
                        return obj
                      }, {})
                  )
                  setOfferType(offerConst)
                  if (
                    // resultData
                    resultData.deliveryPlans[firstDelivery].offerTypes[
                      offerConst
                    ]
                  ) {
                    // resultData
                    const issuesList =
                      resultData.deliveryPlans[firstDelivery].offerTypes[
                        offerConst
                      ].issues

                    chooseIssues(
                      issuesList,
                      firstDelivery,
                      resultData.dtc,
                      resultData.showIssueSelector
                    )
                  }
                }
              }
            }
          } else {
            setNotifyMe(resultData?.notify)
            setIsLoading(false)
            setOpenDrawer(true)
            setOpenPlanConf(true)
          }
        })
    }
  }, [state.cta])

  const chooseIssues = useCallback(
    (issues, dp, dtc, showIssue) => {
      const dpVar = dp ? dp : deliveryPlan
      const dtcVar = dtc ? dtc : dtcOnly
      const showIssueVar = showIssue ? showIssue : showIssueSelector

      setShowIssueSelector(showIssueVar)

      /* Setting the issue and issues state based on the deliveryPlan and offerType. */
      // If DTC is true
      if (dtcVar) {
        /* If Delivery plan is RE and DTC true, it settings the issue to the first issue in the issues array. */
        if (dpVar === "RE") {
          if (showIssueVar) {
            setIssues(issues)
            setIssue(
              issues[
                issues &&
                  Object.keys(issues).filter((filter, index) => index === 0)
              ]
            )
          } else {
            setIssues(null)
            setIssue(
              issues[
                issues &&
                  Object.keys(issues).filter((filter, index) => index === 0)
              ]
            )
          }

          /* Else if Delivery plan is PL and DTC true,  it settings the issue to the first issue in the issues array. */
        } else if (dpVar === "PL") {
          if (showIssueVar) {
            setIssues(issues)
            setIssue(
              issues[
                issues &&
                  Object.keys(issues).filter((filter, index) => index === 0)
              ]
            )
          } else {
            setIssues(null)
            setIssue(
              issues[
                issues &&
                  Object.keys(issues).filter((filter, index) => index === 0)
              ]
            )
          }
        } else {
          if (issues && Object.keys(issues).length <= 1) {
            setIssues(null)
            setIssue(
              issues[
                issues &&
                  Object.keys(issues).filter((filter, index) => index === 0)
              ]
            )
          } else {
            setIssues(issues)
            setIssue(issues[Object.keys(issues)[0]])
          }
        }
      } else {
        // If delivery plan is PL and DTC false, it settings the first element of the issues array
        if (dpVar === "PL") {
          setIssues(null)
          setIssue(
            issues[
              issues &&
                Object.keys(issues).filter((filter, index) => index === 0)
            ]
          )
        } else if (dpVar === "RE") {
          // If delivery plan is RE: if there's a single date missing from issues array,
          // it shows the issues selector and take the first issue
          if (
            Object.keys(issues).filter((filter, index) => {
              return !issues[filter].date
            }) &&
            Object.keys(issues).filter((filter, index) => {
              return !issues[filter]?.date
            }).length > 0
          ) {
            if (issues && Object.keys(issues).length <= 1) {
              setIssues(null)
              setIssue(
                issues[
                  issues &&
                    Object.keys(issues).filter((filter, index) => index === 0)
                ]
              )
            } else {
              setIssues(issues)
              setIssue(issues[Object.keys(issues)[0]])
            }
          } else {
            // If all dates are not null, it shows all the issues with the date near the current day today + tomorrow date
            let i = null
            let newArr = Object.keys(issues)
              .map((filter, index) => {
                const today = new Date()
                const tomorrow = new Date(today)
                tomorrow.setDate(tomorrow.getDate() + 1)
                const mydatestring = issues[filter]?.date
                const oldDate = new Date(mydatestring)

                const difference = tomorrow - oldDate
                if (oldDate <= tomorrow) {
                  i = index

                  return issues[filter]
                }
              })
              .filter(filter => filter)

            let data

            if (newArr.length == 0) data = issues
            else {
              newArr.push(issues[i + 2])
              newArr.filter(filter => filter)
              data = newArr.filter(function (element) {
                return element !== undefined
              })
            }

            setIssues(data)
            setIssue(data[Object.keys(data)[0]])
          }
        } else {
          if (issues && Object.keys(issues).length <= 1) {
            setIssues(null)
            setIssue(
              issues[
                issues &&
                  Object.keys(issues).filter((filter, index) => index === 0)
              ]
            )
          } else {
            setIssues(issues)
            setIssue(issues[Object.keys(issues)[0]])
          }
        }
      }
    },
    [deliveryPlan, dtcOnly, issues, showIssueSelector]
  )

  const checkSku = (DP, OT) => {
    setDeliveryPlan(DP ? DP : deliveryPlan)
    setOfferType(OT ? OT : offerType)
    // setOffersType(
    //   deliveryPlans[DP ? DP : deliveryPlan].offerTypes[OT ? OT : offerType]
    // );

    if ((DP ? DP : deliveryPlan) && (OT ? OT : offerType)) {
      chooseIssues(
        deliveryPlans[DP ? DP : deliveryPlan].offerTypes[OT ? OT : offerType]
          ?.issues
      )
    }
  }

  /**
   * Handle close configurator
   */

  const handleClose = () => {
    dispatch({ type: "toggleCta" })
    setOpenDrawer(false)
  }

  const addToCart = (e, currencyToConf) => {
    e.preventDefault()
    let country = detectAlpha2Country()

    let link = `${process.env.GATSBY_CHECKOUT_BASE_URL}/${country}/subscribe?sku=${issue.sku}`

    if (link) {
      const info = null
      window.location.href =
        link +
        (!!parseInt(info?.checkout_test_check || 0)
          ? "&testId=" +
            (info?.checkout_test_id || "missing") +
            "&testVersion=b"
          : "")
    }

    if (window.initialDataLayer) {
      const { cdevent, product } = window.initialDataLayer

      if (cdevent) {
        window.dataLayer.push({
          ...cdevent,
          offerType: `${planCode}`,
          startingIssue: `${issue}`,
        })
      }

      if (product) {
        window.dataLayer.push({
          event: "addToCart",
          ecommerce: {
            currencyCode: currencyToConf,
            add: {
              products: [
                {
                  ...product,
                  variant: issue.sku,
                  quantity: 1,
                },
              ],
            },
          },
        })
      }
    }
  }

  const handleConfirm = () => {
    const currency = offer.currency
    const issue = selectedIssue || "1"
    const productCode = offer.code
    const productVariant = offer?.offerTypes[deliveryPlan]?.issues[issue]?.sku

    goToCheckout(
      currency,
      deliveryPlan,
      issue,
      productCode,
      productVariant,
      checkoutCode,
      state,
      others?.repo
    )
  }

  const handlePlanChange = plan => {
    setDeliveryPlan(plan.planCode)
    if (!plan?.issues?.map(el => el.number).includes(selectedIssue)) {
      setSelectedIssue(plan?.issues[0]?.number)
    }
  }

  const handleIssueChange = issue => {
    setSelectedIssue(issue)
  }

  return isLoading ? (
    <Backdrop className={classes.backdrop} onClick={handleClose}>
      <Progress />
    </Backdrop>
  ) : (
    <OfferDrawer
      open={openDrawer}
      notifyme={notifyme}
      title={props.title}
      bodyTitle={props.bodyTitle}
      issuesSelectLabel={props.issuesSelectLabel}
      buttonText={props.buttonText}
      openPlanConf={openPlanConf} // --
      deliveryPlans={deliveryPlans} // --
      deliveryPlan={deliveryPlan} // --
      offerTypes={offerTypes} // --
      offerType={offerType} // --
      checkSku={checkSku}
      issues={issues}
      issue={issue} // --
      onPlanChange={chooseIssues}
      onIssueChange={chooseIssues}
      onClose={handleClose}
      onConfirm={handleConfirm}
      productCode={offerData?.code}
      offerData={offerData}
      others={others}
      setIssue={setIssue}
      setOpenPlanConf={setOpenPlanConf}
      addToCart={addToCart}
      currencyConf={currencyConf}
    />
  )
}

export default CtaModule
