import React, { useEffect, useRef, useState, Fragment } from "react"
import { Grid, Container, Box, Hidden } from "@material-ui/core"
import Module from "../components/Module"
import Text from "../components/Text"
import { makeStyles } from "@material-ui/core"
import FluidImage from "../components/FluidImage"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Icon from "../components/Icon"
import { Fade } from "@material-ui/core"
import clsx from "clsx"
import CtaButton from "../components/CtaButton"

const useStyles = makeStyles(theme => ({
  mainTitle: {
    display: "block",
    margin: "0 auto",
    letterSpacing: "normal",
    fontStretch: "normal",
    fontFamily: "Spezia",
    fontSize: "36px",
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3),
    },
  },
  cardDesktop: {
    boxShadow: "0 2px 9px 0 rgba(0, 0, 0, 0.08)",
    border: "solid 1px #e7e7e7",
    background: "#fff",
    minHeight: 380,
    borderBottom: "4px solid #662482",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    position: "relative",
    zIndex: 2,
  },
  badge: {
    padding: "3px 7px 2px 5px",
    borderRadius: "4px",
    background: theme.customPalette.darkishPurple,
    color: "#fff",
    fontSize: 11,
    fontWeight: "bold",
    fontFamily: "Spezia",
    minWidth: 70,
    marginBottom: theme.spacing("3"),
    marginTop: theme.spacing("1"),
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      marginTop: theme.spacing("0"),
      marginBottom: theme.spacing("0"),
    },
  },
  textContent: {
    position: "relative",
    zIndex: 2,
    padding: "0",
    paddingRight: 16,

    [theme.breakpoints.up("sm")]: {
      padding: "24px 80px",
    },
  },
  background: {
    backgroundColor: "#f0f0f0",
    width: "100%",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 1,
  },
  backgroundMobile: {
    backgroundColor: "#f0f0f0",
    width: "100%",
    height: "50%",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 1,
  },
  cardTitle: {
    fontSize: 36,
    fontWeight: "bold",
    fontStretch: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
    fontFamily: "Spezia",
    margin: 0,
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      marginBottom: 8,
    },
  },

  cardSubtitle: {
    fontFamily: "Spezia",
    fontSize: 16,
    fontWeight: "normal",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },

  cardDescription: {
    fontFamily: "Spezia",
    fontSize: 12,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.17,
    letterSpacing: "normal",
    textAlign: "left",
    marginTop: theme.spacing(2),
    display: "block",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      marginTop: theme.spacing(2),
      textAlign: "center",
    },
  },
  cardImageDesktop: {
    position: "relative",
    zIndex: 2,
    "& img": {
      objectFit: "contain",
      objectPosition: "left",
      height: "100%",
      maxHeight: "375px",

      width: "100%",

      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
    },
  },
  cardImageMobile: {
    position: "relative",
    zIndex: 2,
    "& img": {
      objectFit: "contain",
      objectPosition: "center",
      height: "100%",
      maxHeight: "260px",
      width: "100%",
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
  },
  accordion: {
    boxShadow: "0 2px 9px 0 rgba(0, 0, 0, 0.08)",
    border: "solid 1px #e7e7e7",
    background: "#fff",
    borderBottom: "4px solid #662482",
    margin: "8px 0",
    width: "100%",
    "& .MuiAccordion-root.Mui-expanded": {
      margin: "8px 0",
    },
  },
  accordionSummary: {
    padding: 16,
    paddingBottom: 0,

    [theme.breakpoints.up("sm")]: {},
    "& .MuiAccordionSummary-content": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
  },
  accordionSummaryPaddingBottom: {
    paddingBottom: 16,
  },
  accordionDetails: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 0,
    [theme.breakpoints.up("sm")]: {},
  },
  detailsIcon: {
    transform: "rotate(0deg)",
    transition: "all .1s ease-out",
  },
  rotate: {
    transform: "rotate(-180deg)",
    transition: "all .1s ease-out",
  },
  detailsLabel: {
    fontSize: 12,
    fontFamily: "Spezia",
    fontWeight: "bold",
  },
  cta: {
    fontSize: "1.1rem !important",
    fontStyle: "normal",
    fontFamily: "Spezia, 'Roboto', 'Open Sans', Helvetica, Arial, sans-serif",
    lineHeight: "1.6rem !important",
    fontStretch: "normal",
    letterSpacing: "normal",
    marginTop: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
}))

const codeEagolemoss = [
  "H7F230000000",
  "DVF227000000",
  "TKF231000000",
  "YCF228000000",
  "H7F420000000",
  "DVF417000000",
  "TKF416000000",
  "YCF419000000",
  "H7F031000000",
  "DVF028000000",
  "TKF032000000",
  "YCF030000000",
  "F233",
  "F234",
  "F235",
  "F236",
  "F427",
  "F426",
  "F428",
  "F425",
  "F039",
  "F038",
  "F040",
  "F041",
]

const CardDesktop = props => {
  const classes = useStyles()
  const { item, index, cta, repo } = props
  const textContentRef = useRef(null)
  const [h, setH] = useState(0)

  useEffect(() => {
    if (textContentRef.current) {
      setH(textContentRef.current?.clientHeight)
    }
  }, [textContentRef.current, h])

  return (
    <Grid item xs={12} md={12} key={index}>
      <Box className={classes.cardDesktop} mb={3}>
        <Box className={classes.background} style={{ height: `${h}px` }}></Box>
        <Grid container alignItems="center">
          <Grid item xs={6} md={6}>
            <Box className={classes.textContent} ref={textContentRef}>
              {item.specialOfferText && (
                <span className={classes.badge}>{item.specialOfferText}</span>
              )}
              <h2 className={classes.cardTitle}>{item.title}</h2>

              <Text component="p" className={classes.cardSubtitle}>
                {item.subtitle || " "}
              </Text>

              <Text className={classes.cardDescription}>
                {item.description || " "}
              </Text>
              {cta?.label || cta?.title || cta?.productCode ? (
                <CtaButton
                  className={classes.cta}
                  variant="contained"
                  size="large"
                  {...cta}
                  repo={codeEagolemoss.indexOf(cta?.productCode) !== -1 && repo}
                  next
                  trackingObj={repo}
                  trackingAction="cta_what_will_you_receive"
                  trackingLabel={cta?.text || cta?.title}
                >
                  {cta?.text || cta?.title}
                </CtaButton>
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={6} md={6}>
            <FluidImage
              className={classes.cardImageDesktop}
              {...item.mobileImage}
            ></FluidImage>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

const CardMobile = props => {
  const classes = useStyles()
  const { item, index, cta, repo } = props
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    setExpanded(index === 0 && `package${index}`)
  }, [])

  return (
    // <Grid item xs={12} md={12} >
    <Accordion
      key={index}
      className={classes.accordion}
      expanded={expanded === `package${index}`}
      // onChange={index > 0 && handleChange(`package${index}`)}
    >
      <AccordionSummary
        // expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={clsx(
          classes.accordionSummary,
          expanded !== `package${index}` &&
            classes.accordionSummaryPaddingBottom
        )}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          width={"100%"}
        >
          <Box px={10} className={classes.textContent}>
            <h2 className={classes.cardTitle}>{item.title}</h2>

            <Text className={classes.cardSubtitle}>{item.subtitle || " "}</Text>
          </Box>
          {item.specialOfferText && (
            <span className={classes.badge}>{item.specialOfferText}</span>
          )}
        </Box>

        {/* in={expanded !== `package${index}`} */}
        <Fade in={index > 0 ? true : false}>
          <Box display="flex" justifyContent={"flex-end"} width={"100%"}>
            <Box
              display="flex"
              alignItems="center"
              position={"absolute"}
              className={classes.detailsLabel}
              onClick={() => {
                if (index > 0 && !expanded) {
                  setExpanded(`package${index}`)
                } else {
                  setExpanded(false)
                }
              }}
            >
              {item.details_label}
              <Icon
                className={clsx(
                  expanded === `package${index}`
                    ? classes.rotate
                    : classes.detailsIcon
                )}
                name="select"
              ></Icon>
            </Box>
          </Box>
        </Fade>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          width={"100%"}
          position={"relative"}
        >
          <FluidImage
            className={classes.cardImageMobile}
            {...item.mobileImage}
          ></FluidImage>

          {item.description && (
            <Text className={classes.cardDescription}>
              {item.description || " "}
            </Text>
          )}

          <Box className={classes.backgroundMobile}></Box>

          <Box>
            {cta?.label || cta?.title || cta?.productCode ? (
              <CtaButton
                className={classes.cta}
                variant="contained"
                repo={codeEagolemoss.indexOf(cta?.productCode) !== -1 && repo}
                {...cta}
                next
              >
                {cta?.text ? cta?.text : cta?.title}
              </CtaButton>
            ) : null}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
    // </Grid>
  )
}

const PackageV2Module = props => {
  const { items, moduleTitle, cta, ...others } = props
  const classes = useStyles()
  return (
    <Module variant="full">
      <Box px={2}>
        <Container>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Text
                className={classes.mainTitle}
                weight="bold"
                align="center"
                overline
              >
                {moduleTitle || ""}
              </Text>
            </Grid>

            {items.map((item, index) => {
              if (!item.disable_card) {
                return (
                  <Fragment>
                    <Hidden smDown>
                      <CardDesktop
                        cta={cta}
                        index={index}
                        item={item}
                        {...others}
                      />
                    </Hidden>
                    <Hidden mdUp>
                      <CardMobile
                        cta={cta}
                        index={index}
                        item={item}
                        {...others}
                      />
                    </Hidden>
                  </Fragment>
                )
              }
            })}
          </Grid>
        </Container>
      </Box>
    </Module>
  )
}
export default PackageV2Module
