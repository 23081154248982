import React from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Box, Hidden } from "@material-ui/core"
import { showGuides } from "@src/utils/debug"
import FluidImage from "../components/FluidImage"
import Module from "../components/Module"
import Text from "../components/Text"
import Parallax from "../components/Parallax"
import { Parallax as ParallaxItem } from "react-scroll-parallax"
import { useMediaQuery } from "@material-ui/core"

const parallaxHeight = 450

const left = "LEFT"

const useStyles = makeStyles(theme => ({
  about: {
    marginTop: theme.spacing(1 / 2),
    marginBottom: theme.spacing(1 / 2),
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      float: "initial !important",
    },
  },
  contentContainerReverse: {
    display: "flex",
    flexDirection: "row-reverse",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      float: "initial !important",
    },
  },
  editorialContent: {
    float: "left",
    width: "40%",
    marginRight: "5%",
    marginTop: "auto",
    marginBottom: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "white",
      padding: theme.spacing(4, 4, 0, 4),
      marginRight: "initial",
    },
  },
  editorialContentRight: {
    float: "right",
    width: "40%",
    marginLeft: "5%",
    marginTop: "auto",
    marginBottom: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "white",
      padding: theme.spacing(4, 4, 0, 4),
      marginRight: "initial",
    },
  },
  editorialContentCentered: {
    width: "100%",
    marginTop: "auto",
    marginBottom: "auto",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      padding: theme.spacing(4, 4, 0, 4),
      marginRight: "initial",
    },
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    "&:before": {
      [theme.breakpoints.down("sm")]: {
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
  },
  titleCentered: {
    textAlign: "center",
    "&:before": {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  text: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
      padding: theme.spacing(4, 0, 0, 0),
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: 18,
      lineHeight: 1.22,
    },
  },
  imageWrapper: {
    position: "relative",
    //overflow: 'hidden',
    float: "right",
    width: "55%",
  },
  parallaxWrapper: {
    position: "absolute",
    border: showGuides("4px dashed gray"),
    right: -theme.spacing(8),
    top: "50%",
    transform: "translate3d(0, -50%, 0)",
    maxHeight: 450,
    width: "100%",
    zIndex: -1,
  },
  parallaxWrapperLeft: {
    position: "absolute",
    border: showGuides("4px dashed gray"),
    left: -theme.spacing(8),
    top: "50%",
    transform: "translate3d(0, -50%, 0)",
    maxHeight: 450,
    width: "100%",
    zIndex: -1,
  },
  parallax: {
    zIndex: -1,
  },
  background: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "90%", // FIX ** to be align with visual...
    zIndex: -1,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  foreground: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      width: "100%",
    },
  },
  copyright: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  copyrightCentered: {
    paddingTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  copyrightImage: {
    width: "auto",
    height: 65,
    textAlign: "left", // FIX ** align to left...
    [theme.breakpoints.down("sm")]: {
      textAlign: "center", // FIX ** align to left...
      height: 55,
    },
  },
}))

const Copyright = ({ copyright, copyrightImage, centered }) => {
  const classes = useStyles()
  return (
    <div className={centered ? classes.copyrightCentered : classes.copyright}>
      <FluidImage
        className={classes.copyrightImage}
        {...copyrightImage}
      ></FluidImage>
      <Text variant="bodyXS" component="p">
        {copyright}
      </Text>
    </div>
  )
}

const AboutModule = ({
  title,
  text,
  foregroundImage,
  backgroundImage,
  mobileBackgroundImage,
  copyright,
  copyrightImage,
  videoUrl,
  images_gallery_position,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <Module
      className={classes.about}
      classes={{
        container:
          images_gallery_position === left
            ? classes.contentContainerReverse
            : classes.contentContainer,
      }}
      variant="left"
    >
      <Box
        className={
          foregroundImage || backgroundImage
            ? images_gallery_position === left
              ? classes.editorialContentRight
              : classes.editorialContent
            : classes.editorialContentCentered
        }
      >
        <Text
          className={
            foregroundImage || backgroundImage
              ? classes.title
              : classes.titleCentered
          }
          variant="titleXXXL"
          component="h2"
          overline
          weight="bold"
          color={
            foregroundImage || backgroundImage
              ? classes.title
              : classes.titleCentered
          }
        >
          {title}
        </Text>
        <Text className={classes.text} component="p" variant="titleXL">
          {text}
        </Text>
        <Copyright
          centered={!backgroundImage || !foregroundImage}
          copyright={copyright}
          copyrightImage={copyrightImage}
        ></Copyright>
        <Hidden mdUp>
          <FluidImage
            className={classes.foreground}
            {...foregroundImage}
          ></FluidImage>
        </Hidden>
      </Box>
      {foregroundImage || backgroundImage ? (
        <Hidden smDown>
          <Box className={classes.imageWrapper}>
            <Box
              className={
                images_gallery_position === left
                  ? classes.parallaxWrapperLeft
                  : classes.parallaxWrapper
              }
            >
              <Parallax
                className={classes.parallax}
                bgImage={backgroundImage}
                bgMobileImage={backgroundImage}
                /*blur={0}*/
                amount={0.35}
              >
                <div style={{ height: parallaxHeight, width: "100%" }}></div>
              </Parallax>
            </Box>
            <ParallaxItem
              disabled={isMobile}
              className={classes.parallaxItem}
              y={["3", "-3"]}
            >
              <FluidImage
                className={classes.foreground}
                {...foregroundImage}
              ></FluidImage>
            </ParallaxItem>
          </Box>
        </Hidden>
      ) : null}

      {mobileBackgroundImage && mobileBackgroundImage.src ? (
        <Hidden mdUp>
          <div
            className={classes.background}
            style={{
              backgroundImage: `url(${
                mobileBackgroundImage ? mobileBackgroundImage.src : ""
              })`,
            }}
          ></div>
        </Hidden>
      ) : null}
    </Module>
  )
}

export default AboutModule
